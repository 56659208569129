<template>
        <ion-header >
            <ion-toolbar  color="primary">
                <ion-buttons slot="start" >
                    <ion-menu-button />
                </ion-buttons>
                <ion-title class="simplificado">
                    <small>{{Titulo}}</small>
                </ion-title> 
            </ion-toolbar>
        </ion-header>
</template>

<script>
    import {    IonHeader, IonMenuButton, IonTitle, IonToolbar,
                IonButtons, 
            } from '@ionic/vue';
    import { defineComponent } from 'vue';
    export default defineComponent({
        name : "CabeceraSimplificada",
        components: {  IonHeader, IonMenuButton, IonTitle, IonToolbar, IonButtons },
        props : ['Titulo'] 
    })
</script>

<style scoped>
    ion-title.simplificado {
        padding-left: 6px; 
        padding-right: 6px; 
    }
</style>