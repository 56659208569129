
<template>
    <ion-item lines="none" :disabled="bloqueado">
            <ion-label>{{titulo}} </ion-label>
            <!-- Pregunta activa-->                    
                <ion-button size="small" v-if="localData===undefined"
                    class="confirm" color="darksuccess" 
                    :disabled="bloqueado"
                    v-on:click="Actualizar(true)">
                    Si
                </ion-button>
                <ion-button size="small"  v-if="localData===undefined"
                    class="confirm" color="darkdanger"
                    :disabled="bloqueado"
                    v-on:click="Actualizar(false)">
                    No  
                </ion-button>
            <!-- Pregunta respondida, pasamos de fase-->
                <ion-button size="small" v-if="localData!==undefined"
                    class="confirm" fill="outline" color="primary"
                    disabled >
                    {{localData === true ? 'Si' : 'No'}}
                </ion-button>
                <ion-icon :icon="create" slot="end" 
                    v-on:click="Desbloquear"
                    :disabled="bloqueado"
                    color="dark" v-if="localData!==undefined && !bloqueado">
                </ion-icon>
    </ion-item>
</template>

<script>
    import { IonButton, IonItem,IonLabel, IonIcon } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import { create } from 'ionicons/icons';
    
    export default defineComponent({
        name : "VisitAnswerComponent",
        components: {  IonButton, IonItem,IonLabel, IonIcon}, 
        props: ['data', 'titulo', 'propiedad','bloqueado'], 
        data() {
            return { 
                create, 
                localData : this.data
                }    
        } ,
        methods : {
            async Desbloquear() {
                this.localData = undefined; 
                this.Actualizar(undefined)
            },
            async Actualizar(valor){ 
                this.localData = valor
                this.$emit('update-parent-data', this.propiedad, valor);
            }
        }      
})
</script>

<style scoped>
        ion-button.confirm {
            width: 12% !important;
            margin-left: 3px; 
            margin-right: 3px; 
        }
        ion-item{
            --padding-top:0px;
            --inner-padding-top: 0px;
            --min-height : 40px;
            --max-height : 40px;
        }
        ion-icon {
            margin-top: 8px; 
            margin-bottom: 8px; 
        }
        ion-label{
            margin-top: 4px; 
            margin-bottom: 4px; 
        }
</style>